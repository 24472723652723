import App from "@/components/App";
import LogIn from "@/components/LogIn";
import getOwnerStaticPaths from "@/db/static/getOwnerStaticPaths";
import getOwnerStaticProps from "@/db/static/getOwnerStaticProps";
import useLoggedIn from "@/hooks/useLoggedIn";
import {
  ownerDescription,
  ownerDomain,
  ownerFavicon,
  ownerImage,
  ownerTitle,
} from "@/lib/owner";
import { ownerPath } from "@/lib/path";
import { Org } from "@/models/org";
import { Owner } from "@/models/owner";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";

type Props = {
  owner: Owner;
};
const LogInPage: React.FC<Props> = ({ owner }) => {
  const loggedIn = useLoggedIn();
  const router = useRouter();
  const n = (router.query.next as string) || null;

  useEffect(() => {
    if (loggedIn) {
      router.push(ownerPath(owner, n));
    }
  }, [loggedIn, n]);

  return (
    <>
      <App owner={owner}>
        <LogIn org={owner as Org} />
      </App>
      {!!owner && (
        <Head>
          <title>Log In • {ownerTitle(owner)}</title>
          <meta name="description" content={ownerDescription(owner)} />
          <meta name="image" content={ownerImage(owner)} />
          <meta name="url" content={`${ownerDomain(owner)}/login`} />
          <meta name="og:title" content={ownerTitle(owner)} />
          <meta name="og:description" content={ownerDescription(owner)} />
          <meta name="og:image" content={ownerImage(owner)} />
          <meta name="og:url" content={`${ownerDomain(owner)}/login`} />
          {!!ownerFavicon(owner) && (
            <link rel="icon" href={ownerFavicon(owner)} />
          )}
        </Head>
      )}
    </>
  );
};
export default LogInPage;

export const getStaticPaths = getOwnerStaticPaths;
export const getStaticProps = getOwnerStaticProps;
